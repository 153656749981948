<template>
  <div class="dashboard-editor-container">
    Stage: {{ stage === 'production' ? `${stage} v${version}` : stage }}
    <br>
    Updated @ {{ updatedAt }}
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  setup() {
    const revenueDaysSelect = ref([]);
    const memberDaysSelect = ref([]);
    const channelDaysSelect = ref([]);
    const subscribersDaysSelect = ref([]);

    return {
      stage: process.env.VUE_APP_STAGE,
      version: process.env.VUE_APP_VERSION,
      updatedAt: new Date(process.env.VUE_APP_UPDATE_AT).toLocaleString(),

      revenueDaysSelect,
      memberDaysSelect,
      channelDaysSelect,
      subscribersDaysSelect
    };
  }
};
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: #f2f7ff;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
    border-radius: 8px;
    .chart-wrapper-nav{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
